import { EntryFields } from 'contentful';
import { ReactNode } from 'react';
import { Card } from '@features/shared/components/card';
import { RichText } from 'src/features/shared/components/rich-text';
import { useClubs } from '@domains/clubs/hooks';
import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import { useIntl } from '@domains/i18n';
import { ClubOnlyComparisonDialog } from '@features/pg-funnel/components/club-only-comparison';
import { useClubDataFromConfig } from '@features/pg-funnel/hooks/use-club-config';
import { AttributeOption } from '@features/pg-funnel/hooks/use-funnel-config/types';
import { ClubPageHeader } from '@features/shared/components/content-sections/membership/club-page-header';
import {
  Col,
  Container,
  Row,
  Section,
} from '@features/shared/components/layout';
import { MoreInfoButton } from '@features/shared/components/more-info-button';
import { IMembershipItem } from '@features/shared/contentful/types/IMembershipItem';
import { IMembershipSection } from '@features/shared/contentful/types/IMembershipSection';
import { useSheet } from '@features/shared/hooks/use-sheet';
import { GA4_EVENTS, pushGA4Event } from '@features/shared/utils/ga4';
import { maybeString } from '@features/shared/utils/maybeString';

import styles from './index.module.scss';
import classNames from 'classnames';
import { isButtonVariant } from 'src/features/shared/utils/isButtonVariant';
import { SectionHeader } from '../../section-header';

export interface IMembershipProps extends IMembershipSection {
  clubId: number | null;
  stickToTop: boolean;
  stickToBottom: boolean;
}

export function Membership({
  title,
  description,
  cards,
  showComparisonTable,
  clubId,
  backgroundColor,
  stickToTop,
  stickToBottom,
}: IMembershipProps) {
  const { formatNumber, formatMessage } = useIntl();
  const sheet = useSheet();
  const { renderRichText } = useRichTextRenderer();
  const [clubs] = useClubs();
  const { availableClubs } = useClubDataFromConfig(clubId, clubs);

  const getUrlWithClubId = (url: string) => {
    if (!url.includes('checkout')) {
      return url;
    }
    const [path, params] = url.split('?');
    const urlParams = new URLSearchParams(params);

    if (clubId) {
      urlParams.append('clubId', clubId?.toString());
    }

    return `${path}?${urlParams.toString()}`;
  };

  const onClickButton = (value: string) => {
    if (!cards || cards.length === 0) {
      return '/checkout'; // TODO replace with CHECKOUT_URL
    }
    const membership = cards[value === 'one' ? 0 : 1];
    const contractType = membership.link?.href
      .split('contractType=')[1]
      .toLowerCase();
    contractType &&
      pushGA4Event(GA4_EVENTS.trackEvent, {
        event_name: `click_cta_${contractType}_compare`,
      });
    window.location.replace(
      getUrlWithClubId(maybeString(membership.link?.href)),
    );
  };

  const parseDescription = (text: string | EntryFields.RichText): ReactNode => {
    if (!text) return null;

    if (typeof text === 'string') {
      return text;
    }

    return renderRichText(text);
  };

  const getBeforePrice = (membership: IMembershipItem): string | undefined => {
    if (!membership.beforePrice) return undefined;

    return `${formatNumber(membership.beforePrice, {
      style: 'currency',
      currency: 'EUR',
    })}`;
  };

  const onClickMembership = (href?: string) => {
    if (href) {
      const contractType = href.split('contractType=')[1]?.toLowerCase();
      contractType &&
        pushGA4Event(GA4_EVENTS.trackEvent, {
          event_name: `click_cta_${contractType}`,
        });
    }
  };

  const onClickMoreInfo = () => {
    sheet.open();
    pushGA4Event(GA4_EVENTS.trackEvent, { event_name: 'click_more_info' });
  };

  return (
    <Section hasNoBottomMargin={stickToBottom}>
      <Container
        backgroundColor={backgroundColor}
        stickToBottom={stickToBottom}
        stickToTop={stickToTop}
      >
        <Row>
          <Col columns="xs:12 md:10" offset="md:1">
            <div className={styles.container}>
              {clubId ? (
                <ClubPageHeader title={title} />
              ) : (
                <SectionHeader title={title} hasBorder isCentered />
              )}
            </div>
          </Col>
          <Col columns="xs:12 md:10 lg:8" offset="md:1 lg:2">
            {description && !clubId && (
              <div className={classNames(styles.container, styles.description)}>
                <RichText>
                  <>{renderRichText(description)}</>
                </RichText>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col columns="xs:12 md:10 lg:8" offset="md:1 lg:2">
            <div className={styles.cardsContainer}>
              <Row className={styles.cardWrapper}>
                {cards?.map((membership: IMembershipItem, index: number) => (
                  <Col columns="xs:12 md:6" key={membership.title}>
                    <a
                      onClick={() => onClickMembership(membership.link?.href)}
                      href={getUrlWithClubId(
                        maybeString(membership.link?.href),
                      )}
                      target={
                        membership.link?.openInNewTab ? '_blank' : '_self'
                      }
                      rel={
                        membership.link?.openInNewTab ? 'noreferrer' : undefined
                      }
                      data-testid={`club-page_membership_cta_${index + 1}`}
                    >
                      <Card
                        title={membership.title}
                        subtitle={membership.subtitle}
                        image={membership.image}
                        priceDescription={membership.priceDescription}
                        beforePrice={getBeforePrice(membership)}
                        price={formatNumber(membership.price, {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                        priceLabel={membership.priceLabel}
                        description={
                          <>{parseDescription(membership.description)}</>
                        }
                        hasRichText={!!membership.description}
                        buttonLabel={membership.link?.label}
                        buttonVariant={
                          isButtonVariant(membership.buttonStyle)
                            ? membership.buttonStyle
                            : undefined
                        }
                        usps={membership.usps}
                        badge={membership.badge}
                      />
                    </a>
                  </Col>
                ))}
              </Row>
            </div>
            <Row>
              <Col columns="xs:12" className={styles.moreInfoContainer}>
                {!!showComparisonTable && (
                  <MoreInfoButton
                    label={formatMessage(
                      'attribute-choice.what-the-difference',
                    )}
                    handleClick={onClickMoreInfo}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <ClubOnlyComparisonDialog
          sheet={sheet}
          onClickButton={(value) => onClickButton(value)}
          availableClubsAmount={availableClubs?.length}
          options={{
            one: {
              title: formatMessage('club-only.comparison.basic-contract-name'),
            } as AttributeOption,
            plus: {
              title: formatMessage('club-only.comparison.plus-contract-name'),
            } as AttributeOption,
          }}
        />
      </Container>
    </Section>
  );
}
